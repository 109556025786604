import request from "../../utils/request";

/**
 * 用户注册
 */
export const userRegister = (data) => {
    return request({
        url: "/api/users/register",
        method: "post",
        data,
    });
};

/**
 * 获取微信扫码登录二维码

 */
 export const getWxLoginQrcode = (data) => {
    return request({
        url: "/api/mp/getWxLoginQrcode",
        method: "post",
        data,
    });
};

/**
 * 检查是否微信登录成功

 */
 export const checkLogin = (data) => {
    return request({
        url: "/api/mp/checkLogin",
        method: "post",
        data,
    });
};

/**
 * 绑定微信

 */
 export const bindWechat = (data) => {
    return request({
        url: "/api/users/bindWechat",
        method: "post",
        data,
    });
};

/**
 * 用户登录

 */
 export const userLogin = (data) => {
    return request({
        url: "/api/users/login",
        method: "post",
        data,
    });
};

/**
 * 忘记密码

 */
 export const forgotPwd = (data) => {
    return request({
        url: "/api/users/forgotPwd",
        method: "post",
        data,
    });
};

/**
 * 获取用户信息

 */
 export const getUsersInfo = (data) => {
    return request({
        url: "/api/users/getUsersInfo",
        method: "post",
        data,
    });
};

/**
 * 更新用户信息
 * 更新哪个字段传哪个

 */
 export const updateUsersInfo = (data) => {
    return request({
        url: "/api/users/updateUsersInfo",
        method: "post",
        data,
    });
};