import request from "../../utils/request";

/**
 * 发送短信
 */
export const smsSend = (data) => {
    return request({
        url: "/api/sms_send",
        method: "post",
        data,
    });
};

/**
 * 获取用户协议
 */
 export const getUserAgree = (data) => {
    return request({
        url: "/api/getUserAgree",
        method: "post",
        data,
    });
};

/**
 * 获取隐私协议
 */
 export const getPrivacyAgree = (data) => {
    return request({
        url: "/api/getPrivacyAgree",
        method: "post",
        data,
    });
};

/**
 * 获取banner图
 */
 export const getBanner = (data) => {
    return request({
        url: "/api/getBanner",
        method: "post",
        data,
    });
};

/**
 * 获取网站配置
 */
 export const getWebConfig = (data) => {
    return request({
        url: "/api/getWebConfig",
        method: "post",
        data,
    });
};

/**
 * 获取网站导航栏
 */
 export const getWebNav = (data) => {
    return request({
        url: "/api/getWebNav",
        method: "post",
        data,
    });
};


/**
 * 获取关于本站
 */
 export const getWebAbout = (data) => {
    return request({
        url: "/api/getWebAbout",
        method: "post",
        data,
    });
};

/**
 * 获取会员介绍
 */
 export const getVipIntroduce = (data) => {
    return request({
        url: "/api/getVipIntroduce",
        method: "post",
        data,
    });
};

/**
 * 获取免责声明
 */
 export const getStatement = (data) => {
    return request({
        url: "/api/getStatement",
        method: "post",
        data,
    });
};

/**
 * 获取内容列表
 */
 export const getContentList = (data) => {
    return request({
        url: "/api/getContentList",
        method: "post",
        data,
    });
};

/**
 * 获取素材详情
 */
 export const getContentDetails = (data) => {
    return request({
        url: "/api/getContentDetails",
        method: "post",
        data,
    });
};

/**
 * 下载素材
 */
 export const downloadSm = (data) => {
    return request({
        url: "/api/download",
        method: "post",
        data,
    });
};

/**
 * 素材收藏
 */
 export const contentLike = (data) => {
    return request({
        url: "/api/content/like",
        method: "post",
        data,
    });
};

/**
 * 我的收藏
 */
 export const mylike = (data) => {
    return request({
        url: "/api/content/mylike",
        method: "post",
        data,
    });
};

/**
 * 下载记录
 */
 export const downloadLog = (data) => {
    return request({
        url: "/api/download_log",
        method: "post",
        data,
    });
};


/**
 * 素材购买
 */
 export const contentBuy = (data) => {
    return request({
        url: "/api/content/buy",
        method: "post",
        data,
    });
};

/**
 * 购买记录-素材资源
 */
 export const contentBuyLog = (data) => {
    return request({
        url: "/api/content/buyLog",
        method: "post",
        data,
    });
};

/**
 * 获取首页金刚区栏目
 */
 export const getWebNavHome = (data) => {
    return request({
        url: "/api/getWebNavHome",
        method: "post",
        data,
    });
};