import axios from "axios";
import router from "@/router";
import { Message } from 'element-ui';

const service = axios.create({
    baseURL: "https://www.886hq.com", // api的base_url
    timeout: 30000, // 请求超时时间
});

service.interceptors.request.use(
    (config) => {
        if (localStorage.getItem("token")) {
            config.headers.Authorization = 'Bearer ' + localStorage.getItem("token");
        }
        return config;
    },
    (error) => {
        console.log('error', error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    (response) => {
        if (response.data.code == 200) {
            return response.data;
        } else {
            if (response.data.code == 401) {
                Message.error(response.data.msg);
                console.log('response');
                console.log(response);
                setTimeout(() => {
                    router.push("/login");
                }, 100);
            } else {
                // Message.info(response.data.msg);
                // console.log(response.data.msg);
            }
            return Promise.reject(response);
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default service;
