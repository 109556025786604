export const path = {
    index: 'pages/index/index',
    plugInUnit: 'pages/plugPresets/plugPresets',
    downloadMaterial: 'pages/downloadMaterial/downloadMaterial',
    wdhy: 'pages/wdhy/wdhy',
    gmjl: 'pages/gmjl/gmjl',
    xzjl: 'pages/xzjl/xzjl',
    wdsc: 'pages/wdsc/wdsc',
    xxgd: 'pages/xxgd/xxgd',
    jbzl: 'pages/jbzl/jbzl',
    bdwx: 'pages/bdwx/bdwx',
    zhhb: 'pages/zhhb/zhhb',
    mmsz: 'pages/mmsz/mmsz',
    miscellaneous: 'pages/miscellaneous/miscellaneous',
    selfStudy: 'pages/studyRoom/studyRoom',
    communication: 'pages/group/group',
    aboutSite: 'pages/aboutSite/aboutSite',
    statement: 'pages/statement/statement',
}