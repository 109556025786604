<template>
  <div class="con">
    <img class="bg" src="https://fengchao-1315966406.cos.ap-shanghai.myqcloud.com/upload/bg.png" alt="" />
    <!-- 登录页面 -->
    <div class="login">
      <img class="logo" :src="webConfig.logo" alt="" />
      <div class="text1 mb20">请登录您的账号</div>
      <div class="text2">账号*</div>
      <el-input v-model="phone" placeholder="请输入手机号" clearable></el-input>
      <div class="text2 mt20">密码*</div>
      <el-input
        v-model="password"
        placeholder="请输入密码"
        type="password"
        show-password
        @keyup.enter.native="loginClick"
      ></el-input>
      <div class="text3 tar can" @click="navTo('/forgotPassword')">
        忘记密码？
      </div>
      <div class="text4 can" @click="loginClick">立即登录</div>
      <div class="text6 can" @click="navTo('/register')">
        还没有账号立即注册
      </div>
      <div class="text5">第三方微信登录</div>
      <img
        class="weixin can"
        src="../assets/images/weixin.png"
        alt=""
        @click="navTo('/thirdLogin')"
      />
    </div>
  </div>
</template>

<script>
import { userLogin } from "../api/user/index";
import { getWebConfig } from "../api/public/index";
export default {
  name: "FengchaoHtmlLogin",

  data() {
    return {
      // 手机号
      phone: "",
      // 密码
      password: "",
      webConfig: {},
    };
  },

  mounted() {
    this.getWebConfig();
  },

  methods: {
    async getWebConfig() {
      const res = await getWebConfig();
      this.webConfig = res.data;
    },
    loginClick() {
      console.log("登录");
      userLogin({
        mobile: this.phone,
        password: this.password,
      })
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          this.$message.success("登录成功！");
          this.$router.push("/");
        })
        .catch((err) => {
          this.$message.error(err.data.msg);
        });
    },
    navTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.weixin_text {
  font-size: 24px;
  font-weight: 500;
  color: #303030;
  text-align: center;
  margin-bottom: 169px;
}
.weixin_code {
  width: 240px;
  height: 240px;
  background: #444444;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 109px;
  margin-bottom: 34px;
}
.modify_now {
  margin-top: 33px;
  margin-bottom: 22px;
}
/deep/ .el-input__inner {
  width: 442px;
  height: 62px;
  font-size: 18px;
  font-weight: 300;
  color: #b7b7b7;
}
.verification_code {
  width: 111px;
  height: 62px;
  background: #396ce3;
  border-radius: 0px 3px 3px 0px;
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  line-height: 62px;
  position: absolute;
  top: 0;
  right: -1px;
}
.tar {
  text-align: right !important;
}
.register_text {
  font-size: 16px;
  font-weight: 300;
  color: #303030;
  margin-top: 25px;
  margin-bottom: 6px;
}
.xieyi {
  font-size: 16px;
  font-weight: 300;
  color: #396ce3;
  margin-top: 25px;
  margin-bottom: 6px;
}
.text1 {
  font-size: 18px;
  font-weight: 300;
  color: #b5b5b5;
  text-align: center;
}
.text2 {
  font-size: 20px;
  font-weight: 500;
  color: #303030;
  margin-bottom: 7px;
}
.text3 {
  font-size: 16px;
  font-weight: 300;
  color: #396ce3;
  text-align: center;
  margin-top: 13px;
  margin-bottom: 24px;
}
.text6 {
  font-size: 16px;
  font-weight: 300;
  color: #396ce3;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 19px;
}
.text4 {
  width: 442px;
  height: 45px;
  background: #396ce3;
  border-radius: 3px 3px 3px 3px;
  font-size: 17px;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  line-height: 45px;
}
.text5 {
  font-size: 16px;
  font-weight: 300;
  color: #b7b7b7;
  text-align: center;
  margin-bottom: 14px;
}
.weixin {
  width: 37px;
  height: 37px;
  margin-left: 50%;
  transform: translateX(-50%);
}
.logo {
  width: 207px;
  height: 60px;
  margin-top: 37px;
  margin-bottom: 19px;
  margin-left: 50%;
  transform: translateX(-50%);
  object-fit: contain;
}
.login,
.register,
.forgot_password,
.tripartite {
  width: 440px;
  height: 649px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 45px;
}
.register {
  height: 813px;
}
.forgot_password,
.tripartite {
  height: 814px;
}
.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}
</style>