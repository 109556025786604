import Vue from 'vue'
import VueRouter from 'vue-router'
import loginView from '../views/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    component: () => import('../views/index.vue')
  },
  {
    path: '/login',
    name: '登录',
    component: loginView
  },
  {
    path: '/register',
    name: '注册',
    component: () => import('../views/register.vue')
  },
  {
    path: '/forgotPassword',
    name: '忘记密码',
    component: () => import('../views/forgotPassword.vue')
  },
  {
    path: '/thirdLogin',
    name: '第三方登录',
    component: () => import('../views/thirdLogin.vue')
  },
  {
    path: '/userAgree',
    name: '用户协议',
    component: () => import('../views/userAgree.vue')
  },
  {
    path: '/privacyAgree',
    name: '隐私协议',
    component: () => import('../views/privacyAgree.vue')
  },
  {
    path: '/plugInUnit',
    name: '插件预设',
    component: () => import('../views/plugInUnit.vue')
  },
  {
    path: '/search',
    name: '搜索',
    component: () => import('../views/search.vue')
  },
  {
    path: '/selfStudy',
    name: '自习室',
    component: () => import('../views/selfStudy.vue')
  },
  {
    path: '/downloadMaterial',
    name: '素材详情',
    component: () => import('../views/downloadMaterial.vue')
  },
  {
    path: '/accountCenter',
    name: '账号中心',
    component: () => import('../views/accountCenter.vue'),
    children: [
      {
        path: '/wdhy',
        name: '我的会员',
        component: () => import('../views/wdhy.vue')
      },
      {
        path: '/gmjl',
        name: '购买记录',
        component: () => import('../views/gmjl.vue')
      },
      {
        path: '/xzjl',
        name: '下载记录',
        component: () => import('../views/xzjl.vue')
      },
      {
        path: '/wdsc',
        name: '我的收藏',
        component: () => import('../views/wdsc.vue')
      },
      {
        path: '/xxgd',
        name: '消息工单',
        component: () => import('../views/xxgd.vue')
      },
      {
        path: '/jbzl',
        name: '基本资料',
        component: () => import('../views/jbzl.vue')
      },
      {
        path: '/bdwx',
        name: '绑定微信',
        component: () => import('../views/bdwx.vue')
      },
      {
        path: '/zhhb',
        name: '账号换绑',
        component: () => import('../views/zhhb.vue')
      },
      {
        path: '/mmsz',
        name: '密码设置',
        component: () => import('../views/mmsz.vue')
      },
    ],
    redirect: '/wdhy'
  },
  {
    path: '/miscellaneous',
    name: '疑难杂症',
    component: () => import('../views/miscellaneous.vue')
  },
  {
    path: '/communication',
    name: '交流社群',
    component: () => import('../views/communication.vue')
  },
  {
    path: '/aboutSite',
    name: '关于本站',
    component: () => import('../views/aboutSite.vue')
  },
  {
    path: '/vipIntroduce',
    name: '会员介绍',
    component: () => import('../views/vipIntroduce.vue')
  },
  {
    path: '/statement',
    name: '免责声明',
    component: () => import('../views/statement.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
