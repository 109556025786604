<template>
  <div id="app">
    <router-view></router-view>
    <!-- 悬浮 -->
    <div class="to_top flex fd_c at_c">
      <!-- 微信客服 -->
      <div
        class="mt15 flex fd_c at_c can pr w100"
        @mouseenter="wxT = true"
        @mouseleave="wxT = false"
      >
        <img class="xf_img" src="./assets/images/wxkf.png" alt="" />
        <div class="xf_text">微信客服</div>
        <img v-if="wxT" class="wechat_qrcode" :src="wechat_qrcode" alt="" />
      </div>
      <div class="xf_line mt15"></div>
      <!-- 关注我们 -->
      <div
        class="flex fd_c at_c mt5 mb5 can w100"
        @mouseenter="wxT1 = true"
        @mouseleave="wxT1 = false"
      >
        <img class="xf_img" src="./assets/images/gzwm.png" alt="" />
        <div class="xf_text">关注我们</div>
        <img v-if="wxT1" class="wechat_qrcode1" :src="mp_qrcode" alt="" />
      </div>
      <div class="xf_line mt15"></div>
      <!-- 返回顶部 -->
      <div @click="toTop" class="flex fd_c at_c mt5 can">
        <img class="xf_img" src="./assets/images/fhdb.png" alt="" />
        <div class="xf_text">返回顶部</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWebConfig } from "./api/public/index";
import { path } from "./pash";
export default {
  data() {
    return {
      // 微信客服二维码
      wechat_qrcode: "",
      mp_qrcode: "",
      wxT: false,
      wxT1: false,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.updateScaleRatio();
    });
    this.updateScaleRatio();
    this.getWebConfig();
    this.$router.afterEach((to, from, next) => {
      console.log(to, from, next);
      window.scrollTo(0, 0);
    });
  },

  methods: {
    // 返回顶部
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    updateScaleRatio() {
      var width = document.documentElement.clientWidth;
      if (width < 428) {
        console.log("窗口小于428");
        let url = "https://wap.886hq.com/";
        let paths = "";
        switch (this.$route.path) {
          case "/":
            paths = `${url}#/`;
            break;
          // 插件预设
          case "/plugInUnit":
            paths = `${url}#/${path.plugInUnit}?key=${this.$route.query.key}&id=${this.$route.query.id}`;
            break;
          // 下载素材
          case "/downloadMaterial":
            paths = `${url}#/${path.downloadMaterial}?id=${this.$route.query.id}`;
            break;
          // 我的会员
          case "/wdhy":
            paths = `${url}#/${path.wdhy}`;
            break;
          // 购买记录
          case "/gmjl":
            paths = `${url}#/${path.gmjl}`;
            break;
          case "/xzjl":
            paths = `${url}#/${path.xzjl}`;
            break;
          case "/wdsc":
            paths = `${url}#/${path.wdsc}`;
            break;
          case "/xxgd":
            paths = `${url}#/${path.xxgd}`;
            break;
          case "/jbzl":
            paths = `${url}#/${path.jbzl}`;
            break;
          case "/bdwx":
            paths = `${url}#/${path.bdwx}`;
            break;
          case "/zhhb":
            paths = `${url}#/${path.zhhb}`;
            break;
          // 疑难杂症
          case "/miscellaneous":
            paths = `${url}#/${path.miscellaneous}?id=${this.$route.query.id}&title=${this.$route.query.title}&title1=${this.$route.query.title1}`;
            break;
          // 自习室
          case "/selfStudy":
            paths = `${url}#/${path.selfStudy}?id=${this.$route.query.id}&title=${this.$route.query.title}&title1=${this.$route.query.title1}`;
            break;
          // 交流社群
          case "/communication":
            paths = `${url}#/${path.communication}?id=${this.$route.query.id}&title=${this.$route.query.title}&title1=${this.$route.query.title1}`;
            break;
          // 关于本站
          case "/aboutSite":
            paths = `${url}#/${path.aboutSite}`;
            break;
          // 免责声明
          case "/statement":
            paths = `${url}#/${path.statement}`;
            break;
        }
        console.log("paths");
        console.log(paths);
        window.location.href = paths;
      }
    },
    // 获取网站配置
    async getWebConfig() {
      const res = await getWebConfig();
      this.wechat_qrcode = res.data.wechat_qrcode;
      this.mp_qrcode = res.data.mp_qrcode;
    },
  },
};
</script>

<style>
@import url("./assets/css/BaoUi.css");
* {
  margin: 0;
  padding: 0;
}
.v-modal {
  top: 75px !important;
}
.wechat_qrcode {
  width: 120px;
  height: 120px;
  position: absolute;
  top: -15px;
  left: -120px;
}
.wechat_qrcode1 {
  width: 120px;
  height: 120px;
  position: absolute;
  top: 73px;
  left: -120px;
}

.to_top {
  width: 78px;
  height: 255px;
  background: #ffffff;
  box-shadow: 2px 0px 4px 1px rgba(42, 42, 42, 0.09);
  border-radius: 4px 4px 4px 4px;
  position: fixed;
  bottom: 20%;
  right: 39px;
  z-index: 99;
}
.xf_img {
  width: 42px;
  height: 42px;
}

.xf_text {
  font-size: 13px;
  font-weight: normal;
  color: #1a1a1a;
}
.xf_line {
  width: 50px;
  height: 0px;
  opacity: 0.11;
  border: 1px solid #707070;
}
</style>
